/*
 * 工单相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
//工单列表接口
export const selectWorkorderByPagination = (param) => {
  return axios.post(`/personnelapi/knWorkorder/selectWorkorderByPagination`, param);
}

//派发工单接口
export const insertWorkorder = (param) => {
  return axios.post(`/personnelapi/knWorkorder/insertWorkorder`, param);
}

//查询责任人
export const selectDutyMember = (param) => {
  return axios.post(`/personnelapi/hyContractApply/selectDutyMember`, param);
}

//查询工单详情
export const selectWorkorderById = (param) => {
  return axios.get(`/personnelapi/knWorkorder/selectWorkorderById?id=${param}`);
}

//修改工单接口
export const updateWorkorder = (param) => {
  return axios.post(`/personnelapi/knWorkorder/updateWorkorder`, param);
}

//工单作废接口
export const obsoleteWorkorder = (param) => {
  return axios.post(`/personnelapi/knWorkorder/obsoleteWorkorder`, param);
}

// 接收工单接口
export const receiveWorkorder = (param) => {
  return axios.post(`/personnelapi/knWorkorder/receiveWorkorder`, param);
}

// 确认到达
export const arriveWorkorder = (param) => {
  return axios.post(`/personnelapi/knWorkorder/arriveWorkorder`, param);
}
// 确认到达
export const finishWorkorder = (param) => {
  return axios.post(`/personnelapi/knWorkorder/finishWorkorder`, param);
}