import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import { selectBelongContract } from '@/api/officeApi';
import { insertWorkorder, selectDutyMember } from '@/api/workOrder';
import moment from 'moment';
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    ProfileOutlined,
    DollarOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      workorderName: "",
      taskCause: undefined,
      planTime: undefined,
      belongContract: undefined,
      teamMember: []
    });
    const {
      validateTime
    } = getFieldRules();
    const rules = {
      workorderName: [{
        required: true,
        message: '工单名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      taskCause: [{
        required: true,
        message: '任务内容不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      planTime: [{
        required: true,
        message: '计划完成时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      teamMember: [{
        required: true,
        message: '责任人不能为空',
        trigger: 'change',
        type: 'array'
      }]
    };
    const loading = ref(false); //提交

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        obj.operaterId = JSON.stringify(formState.teamMember);
        loading.value = true;
        let res = await insertWorkorder(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        loading.value = false;
        console.log('error', error);
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handleAdd = () => {
      formState.time.push(undefined);
    };

    const onDelete = key => {
      formState.time.splice(key, 1);
    }; //归属合同查询


    const belongContractOptions = ref([]);

    const getBelongContract = async () => {
      let res = await selectBelongContract();

      if (res.code === 200) {
        belongContractOptions.value = [];
        if (res.data) res.data.forEach(item => {
          belongContractOptions.value.push({
            value: item.contractName,
            label: item.contractName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      let res = await selectDutyMember();

      if (res.code === 200) {
        // console.log(res)
        options.value = res.data; // console.log(options.value)
      }
    };

    onMounted(() => {
      getBelongContract();
      getJobCascade();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      disabledDate,
      handleAdd,
      onDelete,
      validateTime,
      belongContractOptions,
      options
    };
  }

});