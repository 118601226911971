import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import workorderModal from './components/workorderModal.vue';
import workorderDetailModal from './components/workorderDetailModal.vue';
import Cookies from "js-cookie";
import { selectBelongContract, updateTravelRevokeApply } from '@/api/officeApi';
import { selectWorkorderByPagination } from '@/api/workOrder';
import { useStore } from 'vuex';
import { QuestionCircleOutlined } from '@ant-design/icons-vue'; //表头

const columns = [{
  title: '工单编号',
  dataIndex: 'workorderNum',
  width: '200px',
  align: 'center'
}, {
  title: '工单名称',
  dataIndex: 'workorderName'
}, {
  title: '发起人',
  dataIndex: 'applicantName'
}, {
  title: '发起时间',
  dataIndex: 'createTime',
  sorter: (a, b) => {
    let aTimeString = a.createTime;
    let bTimeString = b.createTime;
    let aTime = new Date(aTimeString).getTime();
    let bTime = new Date(bTimeString).getTime();
    return aTime - bTime;
  }
}, {
  title: '责任人',
  dataIndex: 'dutyName'
}, {
  title: '计划完成时间',
  dataIndex: 'planTime',
  sorter: (a, b) => {
    let aTimeString = a.planTime;
    let bTimeString = b.planTime;
    let aTime = new Date(aTimeString).getTime();
    let bTime = new Date(bTimeString).getTime();
    return aTime - bTime;
  }
}, {
  title: '归属合同',
  dataIndex: 'belongContract'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    workorderModal,
    workorderDetailModal,
    QuestionCircleOutlined
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      state: null,
      applicantName: null,
      dutyName: null,
      belongContract: null,
      data: []
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const {
        data
      } = formState;
      const [startTime, endTime] = data.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD');
        }

        return item.startOf('day').format('YYYY-MM-DD');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        state: formState.state ? formState.state : null,
        applicantName: formState.applicantName ? formState.applicantName : null,
        dutyName: formState.dutyName ? formState.dutyName : null,
        belongContract: formState.belongContract ? formState.belongContract : null,
        startTime: startTime ? startTime : null,
        endTime: endTime ? endTime : null
      };
      loading.value = true;

      try {
        let res = await selectWorkorderByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //发起申请


    const applyVisible = ref(false);

    const handleApply = () => {
      applyVisible.value = true;
    }; //关闭申请弹窗


    const closeApplyVisible = val => {
      applyVisible.value = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    }; //详情


    const applyDetailVisible = ref(false);
    const applyDetailData = reactive({
      state: 1,
      // data:{}
      key: null,
      applyVisible: false
    }); //查看详情

    const toView = async (item, num) => {
      applyDetailData.state = num;
      applyDetailData.key = item.key;
      applyDetailData.applyVisible = true;
    }; //关闭详情页


    const closeApplyDetailVisible = val => {
      applyDetailData.applyVisible = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    };

    const updateDataValue = val => {
      applyDetailData.data = val;
    }; //归属合同查询


    const belongContractOptions = ref([]);

    const getBelongContract = async () => {
      let res = await selectBelongContract();

      if (res.code === 200) {
        belongContractOptions.value = [];
        if (res.data) res.data.forEach(item => {
          belongContractOptions.value.push({
            value: item.contractName,
            label: item.contractName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //撤销


    const termination = async id => {
      let res = await updateTravelRevokeApply({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getTableData();
      getBelongContract();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      handleApply,
      applyVisible,
      closeApplyVisible,
      toView,
      applyDetailVisible,
      closeApplyDetailVisible,
      applyDetailData,
      isCompanyAdmin,
      updateDataValue,
      belongContractOptions,
      termination
    };
  }

});