import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import { selectBelongContract } from '@/api/officeApi';
import { selectDutyMember, selectWorkorderById, updateWorkorder, obsoleteWorkorder, receiveWorkorder, finishWorkorder, arriveWorkorder } from '@/api/workOrder';
import uploadImgs from '../../dataManagement/components/uploadImgs';
import moment from 'moment';
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    ProfileOutlined,
    DollarOutlined,
    uploadImgs
  },
  props: {
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      workorderName: "",
      taskCause: undefined,
      planTime: undefined,
      belongContract: undefined,
      teamMember: [],
      isInitiator: false,
      scheduleList: [// {
        //   approvalTime: '2022-01-01 15:00:01',
        //   userName: '张三',
        //   levelNum: 1
        // },
        // {
        //   approvalTime: '2022-01-02 15:00:01',
        //   userName: '张三',
        //   levelNum: 2,
        //   fileUrl: '["photos/f2bee76df9168e9d7fdaccd1f15a7e2582.jpg","photos/f2bee76df9168e9d7fdaccd1f15a7e2582.jpg"]',
        //   content: null
        // },
        // {
        //   approvalTime: '2022-01-02 15:00:01',
        //   userName: '张三',
        //   levelNum: 3,
        //   fileUrl: '["photos/f2bee76df9168e9d7fdaccd1f15a7e2582.jpg","photos/f2bee76df9168e9d7fdaccd1f15a7e2582.jpg"]',
        //   content: 'whwdkjhkkk'
        // },
      ],
      levelNum: 1,
      fileUrl: [],
      content: null
    });
    const {
      validateTime,
      signFileurl
    } = getFieldRules();
    const rules = {
      workorderName: [{
        required: true,
        message: '工单名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      taskCause: [{
        required: true,
        message: '任务内容不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      content: [{
        required: true,
        message: '不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      planTime: [{
        required: true,
        message: '计划完成时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      teamMember: [{
        required: true,
        message: '责任人不能为空',
        trigger: 'change',
        type: 'array'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        message: '图片不能为空',
        trigger: formState.fileUrl.length === 0
      }]
    };
    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        //详情
        return true;
      } else if (props.applyDetailData.state == 99 && formState.isInitiator) {
        //待接收 并且是发起人
        return false;
      } else if (props.applyDetailData.state == 34) {
        //待接收 并且不是发起人
        return true;
      } else if (props.applyDetailData.state == 35) {
        //进行中
        return true;
      }
    });
    const loading = ref(false); //修改工单

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        obj.operaterId = JSON.stringify(formState.teamMember);
        loading.value = true;
        let res = await updateWorkorder(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        loading.value = false;
        console.log('error', error);
      });
    }; //确定到达


    const handleArrive = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        obj.operaterId = JSON.stringify(formState.teamMember);
        obj.fileUrl = JSON.stringify(formState.fileUrl);
        loading.value = true;
        let res = await arriveWorkorder(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        loading.value = false;
        console.log('error', error);
      });
    }; //完成


    const handleFinis = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.belongContract = obj.belongContract ? obj.belongContract : null;
        obj.operaterId = JSON.stringify(formState.teamMember);
        obj.fileUrl = JSON.stringify(formState.fileUrl);
        loading.value = true;
        let res = await finishWorkorder(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        loading.value = false;
        console.log('error', error);
      });
    }; //作废


    const termination = async () => {
      loading.value = true;
      let res = await obsoleteWorkorder({
        id: formState.applyId
      });

      if (res.code === 200) {
        _message.success(res.message);

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
        loading.value = false;
      } else {
        _message.error(res.message);

        loading.value = false;
      }
    }; //接收工单


    const handleReceive = async () => {
      loading.value = true;
      const param = {
        applyId: formState.applyId,
        belongProject: formState.belongProject
      };
      let res = await receiveWorkorder(param);

      if (res.code === 200) {
        _message.success(res.message);

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
        loading.value = false;
      } else {
        _message.error(res.message);

        loading.value = false;
      }
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handleAdd = () => {
      formState.time.push(undefined);
    };

    const onDelete = key => {
      formState.time.splice(key, 1);
    }; //归属合同查询


    const belongContractOptions = ref([]);

    const getBelongContract = async () => {
      let res = await selectBelongContract();

      if (res.code === 200) {
        belongContractOptions.value = [];
        if (res.data) res.data.forEach(item => {
          belongContractOptions.value.push({
            value: item.contractName,
            label: item.contractName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      let res = await selectDutyMember();

      if (res.code === 200) {
        // console.log(res)
        options.value = res.data; // console.log(options.value)
      }
    };

    const getData = async key => {
      const res = await selectWorkorderById(key);

      try {
        Object.assign(formState, res.data);
        formState.teamMember = JSON.parse(formState.operaterId);
        console.log(formState);
      } catch (error) {
        console.log('工单详情:', error);
      }
    }; //获取图片的url


    const getImgUrls = val => {
      formState.fileUrl = val;
      console.log(val, formState.fileUrl);
    };

    onMounted(() => {
      getBelongContract();
      getJobCascade();
      getData(props.applyDetailData.key);
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      disabledDate,
      handleAdd,
      onDelete,
      validateTime,
      belongContractOptions,
      options,
      isDisabledForCl,
      termination,
      handleReceive,
      getImgUrls,
      handleArrive,
      handleFinis
    };
  }

});